<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">
                            Tibbiy uskunalar 
                        </div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                v-model="filterForm.search"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <el-col :span="6">
                        <crm-create-and-column-settings
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item> Med Texnikalar </el-breadcrumb-item>
                <el-breadcrumb-item>  Tibbiy uskunalar  </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.clinic.show">
                            {{ columns.clinic.title }}
                        </th>

                        <th v-if="columns.filial.show">
                            {{ columns.filial.title }}
                        </th>

                         <th v-if="columns.region.show">
                            {{ columns.region.title }}
                        </th>

                        <th v-if="columns.date_of_manufacture.show">
                            {{ columns.date_of_manufacture.title }}
                        </th>

                        <th v-if="columns.series_and_number.show">
                            {{ columns.series_and_number.title }}
                        </th>

                        <th v-if="columns.document_number.show">
                            {{ columns.document_number.title }}
                        </th>

                        <th v-if="columns.document_date.show">
                            {{ columns.document_date.title }}
                        </th>

                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.name"
                                :placeholder="columns.name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.clinic.show">
                            <select-clinic
                            :id="filterForm.clinic_id"
                            v-model="filterForm.clinic_id"
                            :size="'mini'"
                            >
                            </select-clinic>
                        </th>

                        <th v-if="columns.filial.show">
                            <select-filial
                            :id="filterForm.filial_id"
                            v-model="filterForm.filial_id"
                            :size="'mini'"
                            :disabled="true"
                            >
                            </select-filial>
                        </th>

                        <th v-if="columns.region.show">
                            <select-region
                                :id="filterForm.region_id"
                                v-model="filterForm.region_id"
                                :size="'mini'"
                            >
                            </select-region>
                        </th>

                        <th v-if="columns.date_of_manufacture.show">
                            <el-date-picker
                                :placeholder="columns.date_of_manufacture.title"
                                v-model="filterForm.date_of_manufacture"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.series_and_number.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.series_and_number"
                                :placeholder="columns.series_and_number.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.document_number.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.document_number"
                                :placeholder="columns.document_number.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.document_date.show">
                            <el-date-picker
                                :placeholder="columns.document_date.title"
                                v-model="filterForm.document_date"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.created_at.show">
                            <el-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.created_at"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <el-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.updated_at"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="(item, index) in list"
                        :key="index"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ item.id }}</td>

                        <td v-if="columns.name.show">
                            {{ item.name }}
                        </td>

                        <td v-if="columns.clinic.show">
                            {{ item.clinic ? item.clinic.legal_entity_name : "" }}
                        </td>

                        <td v-if="columns.filial.show">
                            {{ item.filial ? item.filial.name : "" }}
                        </td>

                        <td v-if="columns.region.show">
                            {{
                                item.clinic ? (item.clinic.region ? item.clinic.region.region_name : "") : ''
                            }}
                        </td>

                        <td v-if="columns.date_of_manufacture.show">
                            {{ item.date_of_manufacture }}
                        </td>

                        <td v-if="columns.series_and_number.show">
                            {{ item.series_and_number }}
                        </td>

                        <td v-if="columns.document_number.show">
                            {{ item.document_number }}
                        </td>

                        <td v-if="columns.document_date.show">
                            {{ item.document_date }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ item.created_at }}
                        </td>
                        <td v-if="columns.updated_at.show">
                            {{ item.updated_at }}
                        </td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-settings
                                :name="$options.name"
                                :model="item"
                                :permissionShow="'medicalEquipmentsController@update'"
                                :permissionDestroy="'medicalEquipmentsController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    :status="true"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                :wrapperClosable="false"
                size="95%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                    :status="false"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import SelectClinic from '@/components/filters/inventory/select-clinic';
import SelectFilial from '@/components/filters/inventory/select-filial';
export default {
    name: "medicalEquipments",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        SelectClinic,
        SelectFilial,
    },
    computed: {
        ...mapGetters({
            list: "medicalEquipments/list",
            columns: "medicalEquipments/columns",
            pagination: "medicalEquipments/pagination",
            statues: "medicalEquipments/statues",
            filter: "medicalEquipments/filter",
            sort: "medicalEquipments/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    data() {
        return {
            filterForm: {},
        };
    },
    methods: {
        ...mapActions({
            updateList: "medicalEquipments/index",
            setPagination: "medicalEquipments/setPagination",
            updateSort: "medicalEquipments/updateSort",
            updateFilter: "medicalEquipments/updateFilter",
            updateColumn: "medicalEquipments/updateColumn",
            updatePagination: "medicalEquipments/updatePagination",
            show: "medicalEquipments/show",
            empty: "medicalEquipments/empty",
            delete: "medicalEquipments/destroy",
            refreshData: "medicalEquipments/refreshData",
        }),
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("medicalEquipments/EMPTY_LIST");
        next();
    },
};
</script>
