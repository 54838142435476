import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
    props: {
        status: {
            type: Boolean
        }
    },
    computed: {
        steps() {
            return [
                {
                    step: 1,
                    stepText: "Qadam 1",
                    
                },
                {
                    step: 2,
                    stepText: "Qadam 2",
                },
                {
                    step: 3,
                    stepText: "Qadam 3",
                },
            ];
        },
        ...mapGetters({
            rules: 'medicalEquipments/rules',
            columns: 'medicalEquipments/columns',
            model: 'medicalEquipments/model',
            old_anothers: 'medicalEquipments/old_anothers',
            filtered_filial: 'clinics/filtered_filial'
        })
    },
    data(){
        return {
            currentStep: 1,
            form: {},
            loadingButton: false,
            loading: false,
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            technique_images: [],
            series_and_number_images: [],
            passport_images: [],
            property_ownership_images: [],
            certificate_images: [],
            another_certificates: [{
                id: 1,
                another_name: '',
                images: []
            }],
            old_another_certificates: [],
            selectedPosition: null,
            isDocument: true,
            isCertificate: true,
            fileStatus: null,
            clinics: []
        } 
    },
    created(){
        this.form = JSON.parse(JSON.stringify(this.model));       
    },
    methods: {
        ...mapActions({
            getFilteredFilials: 'clinics/filteredFilial',
            empty: 'medicalEquipments/empty',
            remoteSearchMethod: 'doctors/remoteMethod'
        }),
        submit(close) {
            let formData  = new FormData();

            for (const key in this.form) {
                if (this.form[key]) {
                    formData.append(key, this.form[key]);
                }
                else if(this.form[key] === false){
                    formData.append(key, this.form[key]);
                }
                else{
                    formData.append('', this.form[key]);
                }
            }

            /**
             * Get Upload Technique Image 
             */
            if (_.size(this.technique_images) > 0) {
                for (const key in this.technique_images){
                    if (this.technique_images.hasOwnProperty(key)) {
                        const element = this.technique_images[key];
                        formData.append(`equipment_images[${key}]`, element);
                    }
                }
            }

            /**
             * Get Upload Serires And Number Images
             */
            if (_.size(this.series_and_number_images) > 0) {
                for (const key in this.series_and_number_images){
                    if (this.series_and_number_images.hasOwnProperty(key)) {
                        const element = this.series_and_number_images[key];
                        formData.append(`equipment_series_and_number_images[${key}]`, element);
                    }
                }
            }

            /**
             * Get Upload Passport Images
             */
            if (_.size(this.passport_images) > 0) {
                for (const key in this.passport_images){
                    if (this.passport_images.hasOwnProperty(key)) {
                        const element = this.passport_images[key];
                        formData.append(`equipment_passport_images[${key}]`, element);
                    }
                }
            }

            /**
             * Get Upload Certificates Images
             */
             if (_.size(this.certificate_images) > 0) {
                for (const key in this.certificate_images){
                    if (this.certificate_images.hasOwnProperty(key)) {
                        const element = this.certificate_images[key];
                        formData.append(`equipment_certificate_images[${key}]`, element);
                    }
                }
            }

            /**
             * Get Upload Property Ownerships Images
              */
            if (_.size(this.property_ownership_images) > 0) {
                for (const key in this.property_ownership_images){
                    if (this.property_ownership_images.hasOwnProperty(key)) {
                        const element = this.property_ownership_images[key];
                        formData.append(`property_ownership_images[${key}]`, element);
                    }
                }
            }
            
            let another_certificates = this.another_certificates.map(item => {
                return {
                    name: item.another_name,
                    images: item.images
                }
            });
            this.buildFormData(formData, another_certificates, 'another_certificates');
            
            this.buildFormData(formData, this.old_another_certificates, 'old_another_certificates');

            // console.log('formData', ...formData.entries());
            
            this.$refs["form"].validate((valid) => {
                if (valid && this.validateUplaodImages()) {
                    this.loadingButton = true;  
                    this.save(formData)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
              Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
              });
            } else {
              const value = data == null ? '' : data;

              formData.append(parentKey, value);
            }
        },
        validateUplaodImages() {
            let has_status = false;
            let extensions = ['jpeg', 'jpg', 'png', 'svg', 'pdf', 'doc', 'docx', 'xls', 'xlsx']; //  
            let errors = [];

            function clean_code(item){
                let ext = _.split(item.name, '.', 2);
                let has_ext = extensions.includes(ext[1]);
                if (!has_ext) {
                    if (!errors.includes(_.upperCase(ext[1]))) {
                        errors.push(_.upperCase(ext[1]));
                    }
                }
            }

            this.technique_images.forEach(elem => {
                clean_code(elem);
            }); 
            this.series_and_number_images.forEach(elem => {
                clean_code(elem);
            });
            this.passport_images.forEach(elem => {
                clean_code(elem);
            });
            this.certificate_images.forEach(elem => {
                clean_code(elem);
            });
            this.property_ownership_images.forEach(elem => {
                clean_code(elem);
            });
            this.another_certificates.forEach(elem => {
                elem.images.forEach(item => {
                    clean_code(item);
                });
            });
    
            if (!_.isEmpty(errors)) {
                const h = this.$createElement;
                let mistake = errors.map(mime => h('li', `${mime}` + '  kengaytmali fayl yuklay olmaysiz'));
                const message = h('ul', mistake);
                    this.$message({
                        message: message,
                        type: 'warning',
                        showClose: true
                    });
                has_status = false;     
            }
            else{
                has_status = true;
            }

            return (has_status) ? true : false;      
        },
        setStep(step) {
            this.currentStep = step;
        },
        prevStep() {
            if (this.currentStep !== 1) {
                this.currentStep--;
            }
        },
        nextStep() {
            if (this.steps.length > this.currentStep) {
                this.currentStep++;
            }
        },
        addAnotherCertificate(){
            let index = this.another_certificates.length + 1;
            this.another_certificates.push({
                id: index,
                another_name: '',
                images: []
            }); 
        },
        deleteAnotherCertificate(index){
            delete this.another_certificates.splice(index, 1);
        },

        updateTechniqueImage(file){
            this.technique_images.push(file.raw); 
        },
        handleTechniqueImageRemove(file){
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.technique_images); i++) {
                    if (this.technique_images[i].name === file.name) {
                        this.technique_images.splice(i, 1);
                    }
                }

            }
        },

        updateSeriesAndNumberImage(file){
            this.series_and_number_images.push(file.raw); 
        },
        handleSeriesAndNumberImageRemove(file){
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.series_and_number_images); i++) {
                    if (this.series_and_number_images[i].name === file.name) {
                        this.series_and_number_images.splice(i, 1);
                    }
                }

            }
        },

        updatePassportImage(file){
            this.passport_images.push(file.raw);    
        },
        handlePassportImageRemove(file){
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.passport_images); i++) {
                    if (this.passport_images[i].name === file.name) {
                        this.passport_images.splice(i, 1);
                    }
                }

            }
        },

        updateCertificateImage(file){
            this.certificate_images.push(file.raw);    
        },
        handleCertificateImageRemove(file){
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.certificate_images); i++) {
                    if (this.certificate_images[i].name === file.name) {
                        this.certificate_images.splice(i, 1);
                    }
                }

            }
        },

        updatePropertyOwnershipImage(file){
            this.property_ownership_images.push(file.raw);    
        },
        handlePropertyOwnershipRemove(file){
            if (file.id) {
                this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                    this.$alert(res);
                }).catch(err => {});
            } else {
                for (var i = 0; i < _.size(this.property_ownership_images); i++) {
                    if (this.property_ownership_images[i].name === file.name) {
                        this.property_ownership_images.splice(i, 1);
                    }
                }

            }
        },

        getItemPosition(id, fileStatus) {
            this.selectedPosition = id;
            this.fileStatus = fileStatus;
        },

        updateAnotherCertificateImage(file){
            let current_index = this.selectedPosition;

            if (!this.status) {
                if (!this.fileStatus){
                    this.old_another_certificates.forEach(item => {
                        if (item.id == current_index) {
                            item.images.push(file.raw);
                        }
                    });        
                }else{
                    this.another_certificates.forEach(item => {
                        if (item.id == current_index) {
                            item.images.push(file.raw);
                        }
                    });
                }
            }else{
                this.another_certificates.forEach(item => {
                    if (item.id == current_index) {
                        item.images.push(file.raw);
                    }
                });
            }
            
        },
        handleAnotherCertificateImageRemoveOld(file){ 
            for (var i = 0; i < _.size(this.old_another_certificates); i++) {
                for (let j = 0; j < _.size(this.old_another_certificates[i].images); j++) {
                    if (this.old_another_certificates[i].images[j].name === file.name) {
                        this.old_another_certificates[i].images.splice(j, 1);
                    }   
                }
            }    
        },
        handleAnotherCertificateImageRemove(file){
            for (var i = 0; i < _.size(this.another_certificates); i++) {
                for (let j = 0; j < _.size(this.another_certificates[i].images); j++) {
                    if (this.another_certificates[i].images[j].name === file.name) {
                        this.another_certificates[i].images.splice(j, 1);
                    }   
                }
            }
        },
        changeDocument(status){
            if ((status === false) || !status) {
                this.isDocument = false;  
            }else{
                this.isDocument = true;
            }
        },
        changeCertificate(status){
            if ((status === false) || !status) {
                this.isCertificate = false;  
            }else{
                this.isCertificate = true;
            }
        },
        remoteMethod(query){
            if (_.isNumber(query)){
                this.loading = true;
                this.remoteSearchMethod({query: query}).then((res) => {
                    if (res.clinics) {
                        this.loading = false;
                        this.clinics = res.clinics;
                    }
                }).catch((err) => {
                    this.loading = false; 
                    console.log('err', err);
                });
            }
            else{
                if (query) {
                    this.loading = true;
                    setTimeout(() => {
                        this.remoteSearchMethod({query: query})
                        .then((res) => {
                            if (res.clinics) {
                                this.loading = false;
                                this.clinics = res.clinics;
                            }
                        }).catch((err) => {
                            this.loading = false;
                            console.log(err);
                        }, 2000);
                    });
                }else{
                    this.clinics = [];
                }
            }
        },
        selectedClinicId(clinic_id){
            if (clinic_id) {
                let query = {clinic_id: clinic_id};
                this.getFilteredFilials(query);     
            }else{
                this.clinics = [];
                this.$store.commit('clinics/EMPTY_FILIAL');
                this.form.filial_id = null;
            }
        },
    }
}
