<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_u", {
                        m: $t("message.medicalTechnique"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>
        <div class="bodal-body-my">
          
                <div class="card-body p30">
                    <div class="steps">
                        <ul class="steps-ul">
                            <li
                                v-for="(item, itemIndex) in steps" :key="'steps-'+itemIndex"
                                :class="{ active: item.step === currentStep }"
                                @click="setStep(item.step)"
                            >
                                {{ item.stepText }}
                            </li>
                        </ul>
                    </div>

                    <div class="steps-body">
                        <el-form ref="form"  :model="form" :rules="rules" class="my-form" enctype="multipart/form-data">
                            <div v-if="currentStep === 1" class="step-itme-1">
                                <el-row :gutter="20" class="mb20">
                                    <el-col :span="10">
                                        <el-form-item
                                            label="Klinikalar"
                                            class="icons-input"
                                             prop="clinic_id" 
                                        >
                                            <el-select
                                            v-model="form.clinic_id"
                                            filterable
                                            clearable
                                            remote
                                            :placeholder="$t('message.clinics')"
                                            :remote-method="remoteMethod"
                                            @change="selectedClinicId"
                                            :loading="loading"
                                            size="large">
                                            <el-option
                                            v-for="item in clinics"
                                            :key="item.id"
                                            :label="item.legal_entity_name"
                                            :value="item.id">
                                            </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item
                                            label="Filiallar"
                                            class="icons-input"
                                        >
                                            <el-select
                                                v-model="form.filial_id"
                                                placeholder="Filial"
                                                clearable
                                                filterable
                                            >
                                                <el-option
                                                    v-for="(
                                                        filial, index
                                                    ) in filtered_filial"
                                                    :key="'filial-' + index"
                                                    :label="
                                                        filial.legal_entity_name +
                                                        ' / ' +
                                                        filial.brand_name
                                                    "
                                                    :value="filial.id"
                                                ></el-option>
                                             </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="20">
                                    <el-divider content-position="left">
                                        Tibbiy Texnikalar
                                    </el-divider>

                                    <el-col :span="8">
                                        <el-form-item  prop="name" label="Texnika nomi">
                                            <el-input
                                                v-model="form.name"
                                                placeholder="Texnika nomi"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="Seriya va raqami">
                                            <el-input
                                                v-model="form.series_and_number"
                                                placeholder="Seriy va raqami"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="Pasport">
                                            <el-select
                                                v-model="form.passport"
                                                placeholder="Pasport"
                                                clearable filterable
                                            >
                                                <el-option
                                                    label="Ha"
                                                    :value="true"
                                                ></el-option>
                                                <el-option
                                                    label="Yo'q"
                                                    :value="false"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item
                                            label="Ishlab chiqarilgan sanasi"
                                        >
                                            <el-date-picker
                                                type="date"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                placeholder="Ishlab chiqarilgan sanasi"
                                                v-model="form.date_of_manufacture"
                                                style="width: 100%"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item label="Qo'llanmasi">
                                            <el-select
                                                v-model="form.manual"
                                                placeholder="Qo'llanmasi"
                                                clearable filterable
                                            >
                                                <el-option
                                                    label="Ha"
                                                    :value="true"
                                                ></el-option>
                                                <el-option
                                                    label="Yo'q"
                                                    :value="false"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                     <el-col :span="8">
                                        <el-form-item label="Uskuna turi" prop="equipment_type_id">
                                            <select-equipment-type
                                            :id="form.equipment_type_id"
                                            v-model="form.equipment_type_id"
                                            :size="'large'"
                                            ></select-equipment-type>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <div class="my-tabs-style">
                                    <el-tabs type="border-card">
                                        <el-tab-pane label="Texnika fotosurati">
                                            <div class="imgUpload">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="/"
                                                    :limit="3"
                                                    accept="file/*"
                                                    name="file"
                                                    multiple
                                                    :file-list="technique_images"
                                                    :auto-upload="false"
                                                    :on-change="
                                                        updateTechniqueImage
                                                    "
                                                    :on-remove="
                                                        handleTechniqueImageRemove
                                                    "
                                                    list-type="text"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                        >Fotosuratni joylang</el-button
                                                    >
                                                    <div
                                                        slot="tip"
                                                        class="
                                                            el-upload__tip
                                                        "
                                                    >
                                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane
                                            label="Seriya va raqami fotosurati"
                                        >
                                            <div class="imgUpload">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="/"
                                                    :limit="3"
                                                    accept="file/*"
                                                    name="file"
                                                    multiple
                                                    :file-list="series_and_number_images"
                                                    :auto-upload="false"
                                                    :on-change="
                                                        updateSeriesAndNumberImage
                                                    "
                                                    :on-remove="
                                                        handleSeriesAndNumberImageRemove
                                                    "
                                                    list-type="text"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                        >Fotosuratni joylang</el-button
                                                    >
                                                    <div
                                                        slot="tip"
                                                        class="
                                                            el-upload__tip
                                                        "
                                                    >
                                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </el-tab-pane>
                                        <el-tab-pane label="Pasporti fotosurati">
                                            <div class="imgUpload">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="/"
                                                    :limit="3"
                                                    accept="file/*"
                                                    name="file"
                                                    multiple
                                                    :file-list="passport_images"
                                                    :auto-upload="false"
                                                    :on-change="
                                                        updatePassportImage
                                                    "
                                                    :on-remove="
                                                        handlePassportImageRemove
                                                    "
                                                    list-type="text"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                        >Fotosuratni joylang</el-button
                                                    >
                                                    <div
                                                        slot="tip"
                                                        class="
                                                            el-upload__tip
                                                        "
                                                    >
                                                       jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                    </div>
                                                </el-upload>
                                            </div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>

                                <div class="step-btn">
                                    <el-button
                                        class="asosy-btn-d"
                                        @click="nextStep"
                                        type="primary"
                                        icon="el-icon-right"
                                    >
                                        Keyingi
                                    </el-button>
                                </div>
                            </div>

                            <div v-if="currentStep === 2" class="step-itme-2">
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <el-form-item label="Guvohnomasi">
                                            <el-select
                                                v-model="form.document"
                                                placeholder="Guvohnomasi"
                                                @change="changeDocument"
                                                clearable filterable
                                            >
                                                <el-option
                                                    label="Ha"
                                                    :value="true"
                                                ></el-option>
                                                <el-option
                                                    label="Yo'q"
                                                    :value="false"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8" v-show="isDocument">
                                        <el-form-item label="Guvohnomasi va raqami">
                                            <el-input
                                                v-model="form.document_number"
                                                placeholder="Guvohnomasi va raqami"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8" v-show="isDocument">
                                        <el-form-item label="Guvohnomasi sanasi">
                                            <el-date-picker
                                                type="date"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                placeholder="Guvohnomasi sanasi"
                                                v-model="form.document_date"
                                                style="width: 100%"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="20">
                                    <el-divider content-position="left">
                                        Standartlashtirishdan qachon o'tgan
                                        sertifikati
                                    </el-divider>
                                    <el-col :span="8">
                                        <el-form-item label="Sertifikati">
                                            <el-select
                                                v-model="form.certificate"
                                                placeholder="Sertifikati"
                                                @change="changeCertificate"
                                                clearable filterable
                                            >
                                                <el-option
                                                    label="Ha"
                                                    :value="true"
                                                ></el-option>
                                                <el-option
                                                    label="Yo'q"
                                                    :value="false"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8" v-show="isCertificate">
                                        <el-form-item label="Sertifikati va raqami">
                                            <el-input
                                                v-model="form.certificate_number"
                                                placeholder="sertifikati va raqami"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8"  v-show="isCertificate">
                                        <el-form-item label="Sertifikati sanasi">
                                            <el-date-picker
                                                type="date"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                placeholder="Sertifikati sanasi"
                                                v-model="form.certificate_date"
                                                style="width: 100%"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                            <div class="imgUpload">
                                                <el-upload
                                                    class="upload-demo"
                                                    action="/"
                                                    :limit="3"
                                                    accept="file/*"
                                                    name="file"
                                                    multiple
                                                    :with-credentials="true"
                                                    :auto-upload="false"
                                                    :on-change="
                                                        updateCertificateImage
                                                    "
                                                    :on-remove="
                                                        handleCertificateImageRemove
                                                    "
                                                    list-type="text"
                                                    :file-list="certificate_images"
                                                >
                                                    <el-button
                                                        size="small"
                                                        type="primary"
                                                        >Fotosuratni joylang</el-button
                                                    >
                                                    <div
                                                        slot="tip"
                                                        class="
                                                            el-upload__tip
                                                        "
                                                    >
                                                      jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                    </div>
                                                </el-upload>
                                            </div>
                                    </el-col>
                                </el-row>
                                <el-divider content-position="left">
                                    Uskunaning boshka sertifikatlari
                                </el-divider>
                                  <el-row :gutter="20" v-for="(another, index) in old_another_certificates" :key="index">
                                    <el-col :span="8">
                                        <el-form-item label="Boshka sertifikatlari">
                                            <el-input
                                                v-model="another.name"
                                                placeholder="Boshka sertifikatlari"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="file/*"
                                                name="file"
                                                multiple
                                                :file-list="another.images"
                                                :auto-upload="false"
                                                @input.native="getItemPosition(another.id, false)"
                                                :on-change="
                                                    updateAnotherCertificateImage
                                                "
                                                :on-remove="
                                                    handleAnotherCertificateImageRemoveOld
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="
                                                        el-upload__tip
                                                    "
                                                >
                                                    jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-col>
                                    <!-- <i
                                        class="delet-icon el-icon-circle-close"
                                        @click="deleteAnotherCertificate(index)"
                                    ></i> -->
                                </el-row>
                                <el-row :gutter="20" v-for="(another, index) in another_certificates" :key="'another-'+index">
                                    <el-col :span="8">
                                        <el-form-item label="Boshka sertifikatlari">
                                            <el-input
                                                v-model="another.another_name"
                                                placeholder="Boshka sertifikatlari"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="file/*"
                                                name="file"
                                                multiple
                                                :file-list="another.images"
                                                :auto-upload="false"
                                                @input.native="getItemPosition(another.id, true)"
                                                :on-change="
                                                    updateAnotherCertificateImage
                                                "
                                                :on-remove="
                                                    handleAnotherCertificateImageRemove
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="
                                                        el-upload__tip
                                                    "
                                                >
                                                    jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-col>
                                    <i
                                class="delet-icon el-icon-circle-close"
                                @click="deleteAnotherCertificate(index)"
                            ></i>
                                </el-row>
                                <div class="w-100">
                                    <div class="add-form-educet">
                                        <el-button
                                            class="asosy-btn-d"
                                            @click="addAnotherCertificate"
                                            icon="el-icon-plus"
                                        >
                                            Qo'shish
                                        </el-button>
                                    </div>
                                </div>

                                <div class="step-btn">
                                    <el-button
                                        class="asosy-btn-y"
                                        @click="prevStep"
                                        icon="el-icon-back"
                                    >
                                        Oldingi
                                    </el-button>
                                    <el-button
                                        class="asosy-btn-d"
                                        @click="nextStep"
                                        type="primary"
                                        icon="el-icon-right"
                                    >
                                        Keyingi
                                    </el-button>
                                </div>
                            </div>

                            <div v-if="currentStep === 3" class="step-itme-3">
                                <el-row :gutter="20">
                                    <el-divider content-position="left">
                                        Mulk huquqi
                                    </el-divider>
                                    <el-col :span="24">
                                        <el-form-item label=" Mulk huquqi">
                                            <select-property-ownerships
                                            :id="form.property_ownership_id"
                                            v-model="form.property_ownership_id"
                                            :size="'medium'"
                                            >
                                            </select-property-ownerships>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                    <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="file/*"
                                                name="file"
                                                multiple
                                                :auto-upload="false"
                                                :on-change="
                                                    updatePropertyOwnershipImage
                                                "
                                                :on-remove="
                                                    handlePropertyOwnershipRemove
                                                "
                                                list-type="text"
                                                :file-list="property_ownership_images"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="
                                                        el-upload__tip
                                                    "
                                                >
                                                    jpg, jpeg, png, svg, pdf, docx, xlsx formatdagi fayllarini yuklash mumkun 5 mb katta bo'lmagan
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-col>
                                </el-row>
                    
                                <el-row :gutter="20" class="mt-20">
                                    <el-divider content-position="left">
                                        Uskunaning Holati Darajasi
                                    </el-divider>
                                    <el-col :span="8">
                                        <el-form-item label="Muddati">
                                            <select-conditions
                                            :id="form.condition_id"
                                            v-model="form.condition_id"
                                            :size="'medium'"
                                            >
                                            </select-conditions>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="8">
                                        <el-form-item
                                            label="Qancha vaqt foydalanish mumkin"
                                        >
                                            <el-input-number
                                                v-model="form.how_long_use_it"
                                                :min="0"
                                                placeholder="Qancha vaqt foydalanish mumkin"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <div class="step-btn">
                                    <el-button
                                        class="asosy-btn-y"
                                        @click="prevStep"
                                        icon="el-icon-back"
                                    >
                                        Oldingi
                                    </el-button>
                                    <el-button
                                        class="asosy-btn-d"
                                        @click="nextStep"
                                        type="primary"
                                        icon="el-icon-right"
                                    >
                                        Keyingi
                                    </el-button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
  
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapActions } from "vuex";
import medicalEquipments from "@/utils/mixins/models/medicalEquipments";
import drawer from "@/utils/mixins/drawer";
import selectClinic from "@/components/filters/inventory/select-clinic.vue";
import SelectPropertyOwnerships from "@/components/filters/inventory/select-property-ownerships.vue";
import SelectConditions from "@/components/filters/inventory/select-conditions.vue";
export default {
    components: { selectClinic, SelectPropertyOwnerships, SelectConditions },
    mixins: [medicalEquipments, drawer],
    props:{
        selectedItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        };
    },
    computed: {},
    methods: {
        ...mapActions({
            save: "medicalEquipments/update",
            show: 'medicalEquipments/show'
        }),

        load(){
            if (!this.loadingData) {
                let model_id = this.selectedItem.id;
                this.show(model_id)
                .then((res) => {
                    this.form = JSON.parse(JSON.stringify(this.model));
                    if (!this.form.document) {
                        this.isDocument = false;
                    }
                    if (!this.form.certificate) {
                        this.isCertificate = false;
                    }
                    if (this.form.clinic_id) {
                        this.remoteMethod(parseInt(this.form.clinic_id));
                    }
                    if (this.form.filial_id) {
                        let query = {clinic_id: this.form.clinic_id, filial_id: this.form.filial_id};
                        this.getFilteredFilials(query);
                    }

                    this.old_another_certificates = JSON.parse(JSON.stringify(this.old_anothers));
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
        afterOpen(){
            this.form = JSON.parse(JSON.stringify(this.model));
            this.load();
        },
        afterLeave(){
            this.clinics = [];
            this.technique_images = [];
            this.series_and_number_images = [];
            this.passport_images = [];
            this.property_ownership_images = [];
            this.certificate_images = [];
            this.another_certificates = [];
            this.old_another_certificates = [];
        }
    },
};
</script>
<style lang="scss">
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
</style>
